import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterEmptyTable: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="83.9941" cy="84" r="80" fill="#F7FAFE" />
    <g filter="url(#filter0_d_561_17930)">
      <circle cx="83.9941" cy="84" r="60" fill="#F2F6FE" />
    </g>
    <g filter="url(#filter1_d_561_17930)">
      <circle cx="83.9941" cy="84" r="40" fill="#E6EDFD" />
    </g>
    <ellipse cx="83.5" cy="115" rx="11.5" ry="2" fill="#CCDCFB" />
    <g filter="url(#filter2_di_561_17930)">
      <path
        d="M54.4473 79.7792C54.4473 80.7588 55.2415 81.5529 56.2211 81.5529L109.779 81.5529C110.759 81.5529 111.553 80.7588 111.553 79.7792L111.553 70.2213C111.553 69.2417 110.759 68.4475 109.779 68.4475L56.2211 68.4475C55.2415 68.4475 54.4473 69.2417 54.4473 70.2213L54.4473 79.7792Z"
        fill="url(#paint0_radial_561_17930)"
        stroke="#6695F3"
        strokeWidth="1.10541"
      />
      <line x1="73.4182" y1="69.0001" x2="73.4182" y2="81.0001" stroke="#6292F4" strokeWidth="0.836364" />
      <line x1="92.4182" y1="69.0001" x2="92.4182" y2="81.0001" stroke="#6292F4" strokeWidth="0.836364" />
    </g>
    <g filter="url(#filter3_i_561_17930)">
      <g filter="url(#filter4_d_561_17930)">
        <path
          d="M54.4473 91.7792C54.4473 92.7588 55.2415 93.5529 56.2211 93.5529L109.779 93.5529C110.759 93.5529 111.553 92.7588 111.553 91.7792L111.553 86.2213C111.553 85.2417 110.759 84.4475 109.779 84.4475L56.2211 84.4475C55.2415 84.4475 54.4473 85.2417 54.4473 86.2213L54.4473 91.7792Z"
          fill="url(#paint1_radial_561_17930)"
          stroke="#A4BDED"
          strokeWidth="1.10541"
        />
        <line x1="73.4182" y1="85.0002" x2="73.4182" y2="93.0002" stroke="#A4BDED" strokeWidth="0.836364" />
        <line x1="92.4182" y1="85.0002" x2="92.4182" y2="93.0002" stroke="#A4BDED" strokeWidth="0.836364" />
      </g>
    </g>
    <g filter="url(#filter5_i_561_17930)">
      <g filter="url(#filter6_d_561_17930)">
        <path
          d="M54.4473 103.779C54.4473 104.759 55.2415 105.553 56.2211 105.553L109.779 105.553C110.759 105.553 111.553 104.759 111.553 103.779L111.553 98.2213C111.553 97.2417 110.759 96.4475 109.779 96.4475L56.2211 96.4475C55.2415 96.4475 54.4473 97.2417 54.4473 98.2213L54.4473 103.779Z"
          fill="url(#paint2_radial_561_17930)"
          stroke="#A4BDED"
          strokeWidth="1.10541"
        />
        <line x1="73.4182" y1="97.0002" x2="73.4182" y2="105" stroke="#A4BDED" strokeWidth="0.836364" />
        <line x1="92.4182" y1="97.0002" x2="92.4182" y2="105" stroke="#A4BDED" strokeWidth="0.836364" />
      </g>
    </g>
    <defs>
      <filter id="filter0_d_561_17930" x="9.99414" y="10" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.39 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_561_17930" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_561_17930" result="shape" />
      </filter>
      <filter id="filter1_d_561_17930" x="-0.00585938" y="0" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_561_17930" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_561_17930" result="shape" />
      </filter>
      <filter
        id="filter2_di_561_17930"
        x="37.9261"
        y="54.2076"
        width="90.1479"
        height="46.1476"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.28121" />
        <feGaussianBlur stdDeviation="7.98424" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_561_17930" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_561_17930" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.28305" dy="4.63165" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_561_17930" />
      </filter>
      <filter
        id="filter3_i_561_17930"
        x="53.8945"
        y="83.8949"
        width="58.2109"
        height="10.2107"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="3.34545" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_561_17930" />
      </filter>
      <filter
        id="filter4_d_561_17930"
        x="37.9261"
        y="70.2076"
        width="90.1479"
        height="42.1476"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.28121" />
        <feGaussianBlur stdDeviation="7.98424" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_561_17930" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_561_17930" result="shape" />
      </filter>
      <filter
        id="filter5_i_561_17930"
        x="53.8945"
        y="95.8949"
        width="58.2109"
        height="10.2107"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="3.34545" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_561_17930" />
      </filter>
      <filter
        id="filter6_d_561_17930"
        x="37.9261"
        y="82.2076"
        width="90.1479"
        height="42.1476"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.28121" />
        <feGaussianBlur stdDeviation="7.98424" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_561_17930" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_561_17930" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_561_17930"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(64.5632 77.2377) rotate(-9.76668) scale(49.401 21.8866)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_561_17930"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(64.5632 90.4919) rotate(-6.54629) scale(49.0045 14.7092)"
      >
        <stop offset="0.221581" stopColor="#D6E4FF" />
        <stop offset="0.729167" stopColor="#E1E8F5" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_561_17930"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(64.5632 102.492) rotate(-6.54629) scale(49.0045 14.7092)"
      >
        <stop offset="0.221581" stopColor="#D6E4FF" />
        <stop offset="0.729167" stopColor="#E1E8F5" />
      </radialGradient>
    </defs>
  </svg>
);

IconControlCenterEmptyTable.defaultProps = defaultIconProps;
