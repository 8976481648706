import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterLineChartBg: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="83.9941" cy="84" r="80" fill="#F7FAFE" />
    <g filter="url(#filter0_d_1954_30395)">
      <circle cx="83.9941" cy="84" r="60" fill="#F2F6FE" />
    </g>
    <g filter="url(#filter1_d_1954_30395)">
      <circle cx="83.9941" cy="84" r="40" fill="#E6EDFD" />
    </g>
    <ellipse cx="84.5" cy="117" rx="11.5" ry="3" fill="#CCDCFB" />
    <g filter="url(#filter2_di_1954_30395)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.076 54.1192C129.833 56.4262 130.197 60.5313 127.89 63.2881L97.2924 99.8508L78.5468 82.2015L55.8622 111.608C53.6665 114.454 49.5791 114.981 46.7328 112.786C43.8865 110.59 43.3591 106.503 45.5548 103.656L77.0128 62.8773L96.1671 80.9114L117.907 54.9335C120.214 52.1767 124.319 51.8121 127.076 54.1192Z"
        fill="url(#paint0_radial_1954_30395)"
      />
    </g>
    <defs>
      <filter id="filter0_d_1954_30395" x="9.99414" y="10" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.39 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30395" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30395" result="shape" />
      </filter>
      <filter id="filter1_d_1954_30395" x="-0.00585938" y="0" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30395" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30395" result="shape" />
      </filter>
      <filter
        id="filter2_di_1954_30395"
        x="25.1065"
        y="36.2365"
        width="123.394"
        height="99.7251"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.72753" />
        <feGaussianBlur stdDeviation="9.54637" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30395" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30395" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.92539" dy="5.53784" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1954_30395" />
      </filter>
      <radialGradient
        id="paint0_radial_1954_30395"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(102.224 61.2944) rotate(90) scale(34.6721 46.1898)"
      >
        <stop stopColor="#316BFF" />
        <stop offset="1" stopColor="#013FBD" />
      </radialGradient>
    </defs>
  </svg>
);

IconControlCenterLineChartBg.defaultProps = defaultIconProps;
