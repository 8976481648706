import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterCostsSet: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 228 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g filter="url(#filter0_i_1895_33807)">
      <ellipse
        cx="26.8559"
        cy="27.0138"
        rx="26.8559"
        ry="27.0138"
        transform="matrix(0.0973577 -0.995235 0.995376 0.0962088 168.389 80.2958)"
        fill="url(#paint0_radial_1895_33807)"
      />
    </g>
    <path
      d="M200.421 30.316C214.792 31.705 225.3 44.4088 223.904 58.6811C222.508 72.9533 209.734 83.4068 195.363 82.0178C180.993 80.6287 170.484 67.9249 171.88 53.6527C173.277 39.3805 186.05 28.927 200.421 30.316Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <path
      d="M195.55 69.3643C195.05 69.3103 194.794 69.0069 194.848 68.5099L195.055 66.618C189.93 65.9026 187.315 63.544 186.665 61.3973C186.515 60.9433 186.468 60.5488 186.507 60.1961C186.654 58.8493 187.773 58.0293 189.559 58.222C190.862 58.3627 191.654 59.0484 192.149 60.1401C192.593 61.2099 193.528 62.0733 195.512 62.4334L196.048 57.5274L194.263 56.9941C189.582 55.5316 187.133 53.3045 187.529 49.6811C187.976 45.5927 192.134 43.4136 197.545 43.8192L197.761 41.8472C197.815 41.3502 198.131 41.1085 198.631 41.1624C199.13 41.2164 199.404 41.5217 199.35 42.0187L199.133 44.0068C203.749 44.7484 206.486 46.9743 207.106 49.4095C207.21 49.7777 207.257 50.1721 207.215 50.5569C207.082 51.7754 205.962 52.4332 204.338 52.2578C203.07 52.121 202.231 51.5276 201.613 50.4064C201.083 49.3109 200.154 48.5457 198.676 48.1914L198.152 52.9852L199.952 53.5365C204.879 55.074 207.218 57.1433 206.826 60.7347C206.372 64.8872 202.319 67.0939 196.646 66.7735L196.437 68.6814C196.383 69.1784 196.05 69.4182 195.55 69.3643ZM193.586 49.7995C193.46 50.9539 194.422 51.7391 196.598 52.493L197.09 47.9878C194.932 47.9008 193.714 48.6291 193.586 49.7995ZM200.794 60.7163C200.929 59.4818 200.024 58.8326 197.602 58.0196L197.103 62.5889C199.316 62.6657 200.654 61.9989 200.794 60.7163Z"
      fill="white"
    />
    <g filter="url(#filter1_i_1895_33807)">
      <ellipse
        cx="18.5322"
        cy="18.6288"
        rx="18.5322"
        ry="18.6288"
        transform="matrix(0.249741 -0.968277 0.969061 0.246959 134.279 36.2905)"
        fill="url(#paint1_radial_1895_33807)"
      />
    </g>
    <path
      d="M161.368 5.85573C170.871 8.27741 176.592 17.8947 174.158 27.3297C171.725 36.7646 162.054 42.4595 152.552 40.0378C143.049 37.6161 137.328 27.9988 139.762 18.5638C142.195 9.12891 151.866 3.43405 161.368 5.85573Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <path
      d="M153.966 31.6954C153.631 31.606 153.489 31.3723 153.579 31.0392L153.921 29.7713C150.504 28.7445 148.972 26.8616 148.757 25.3299C148.704 25.0047 148.714 24.7308 148.778 24.4944C149.022 23.5919 149.872 23.1506 151.068 23.4699C151.942 23.7029 152.409 24.2537 152.63 25.0499C152.818 25.8258 153.364 26.5127 154.678 26.967L155.566 23.6791L154.406 23.1278C151.371 21.6384 149.939 19.8626 150.594 17.4343C151.334 14.6944 154.401 13.6465 158.046 14.4923L158.403 13.1707C158.493 12.8376 158.734 12.706 159.069 12.7954C159.404 12.8848 159.558 13.1217 159.468 13.4548L159.108 14.7872C162.176 15.7783 163.805 17.5837 163.968 19.3088C164 19.5707 163.99 19.8446 163.92 20.1025C163.7 20.9191 162.867 21.2497 161.778 20.9592C160.928 20.7325 160.42 20.2398 160.118 19.4105C159.873 18.6079 159.321 17.9886 158.351 17.5916L157.484 20.8042L158.652 21.3694C161.847 22.9358 163.221 24.5925 162.571 26.9993C161.82 29.7822 158.822 30.86 154.989 30.0447L154.644 31.3233C154.554 31.6564 154.301 31.7848 153.966 31.6954ZM154.711 18.1523C154.502 18.9259 155.074 19.5624 156.477 20.3052L157.292 17.286C155.83 16.9996 154.922 17.3679 154.711 18.1523ZM158.462 26.3522C158.685 25.5248 158.137 24.9871 156.573 24.1781L155.746 27.2403C157.247 27.5255 158.229 27.2117 158.462 26.3522Z"
      fill="white"
    />
    <g filter="url(#filter2_i_1895_33807)">
      <ellipse
        cx="33.3519"
        cy="33.5306"
        rx="33.3519"
        ry="33.5306"
        transform="matrix(-0.246768 -0.969106 0.969748 -0.243983 16.6367 95.8251)"
        fill="url(#paint2_radial_1895_33807)"
      />
    </g>
    <path
      d="M32.9101 23.8551C50.4006 19.4546 68.1603 29.982 72.5845 47.3568C77.0087 64.7315 66.4261 82.3897 48.9355 86.7902C31.4449 91.1908 13.6853 80.6633 9.26109 63.2886C4.83688 45.9138 15.4195 28.2556 32.9101 23.8551Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <path
      d="M43.7521 71.7225C43.1451 71.868 42.718 71.6203 42.5716 71.0169L42.0143 68.72C35.7241 70.0221 31.6729 68.3569 30.0062 66.1201C29.6402 65.6517 29.4185 65.2106 29.3146 64.7823C28.9179 63.1473 29.8795 61.7221 32.0472 61.2024C33.6296 60.8231 34.8449 61.2938 35.8842 62.3628C36.8537 63.428 38.3112 64.0466 40.7816 63.6397L39.3363 57.6836L37.0261 57.8049C30.938 58.0493 27.1367 56.4684 26.0693 52.0694C24.8649 47.1059 28.8037 42.8249 35.2982 41.0413L34.7173 38.6471C34.5708 38.0437 34.8374 37.6297 35.4443 37.4841C36.0513 37.3386 36.5001 37.5812 36.6465 38.1846L37.2321 40.5982C42.939 39.539 47.0777 40.9978 48.8294 43.5849C49.1068 43.9716 49.3285 44.4128 49.4418 44.8799C49.8008 46.3592 48.7702 47.5949 46.7976 48.0679C45.2586 48.4369 44.0283 48.0933 42.8326 47.0412C41.7499 45.9825 40.3424 45.4755 38.4648 45.6785L39.877 51.4984L42.2136 51.3913C48.6194 51.1326 52.2265 52.5747 53.2844 56.9348C54.5077 61.9761 50.7037 66.2454 43.9388 68.238L44.5008 70.5543C44.6472 71.1577 44.359 71.577 43.7521 71.7225ZM33.1969 49.6809C33.5369 51.0823 34.9926 51.5984 37.8533 51.5716L36.5262 46.1021C33.9674 46.9009 32.8521 48.2599 33.1969 49.6809ZM46.2285 59.4297C45.8648 57.9309 44.5337 57.5497 41.36 57.6103L42.7061 63.1577C45.3251 62.3239 46.6064 60.9868 46.2285 59.4297Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_1895_33807"
        x="170.875"
        y="29.311"
        width="54.0352"
        height="53.7118"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.04979" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_33807" />
      </filter>
      <filter
        id="filter1_i_1895_33807"
        x="138.318"
        y="4.41763"
        width="37.2832"
        height="37.0583"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.04979" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_33807" />
      </filter>
      <filter
        id="filter2_i_1895_33807"
        x="7.37305"
        y="21.9739"
        width="67.0996"
        height="66.6976"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.04979" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_33807" />
      </filter>
      <radialGradient
        id="paint0_radial_1895_33807"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-31.0617 23.5053) rotate(14.7624) scale(100.299 90.0211)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1895_33807"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-21.4345 16.2093) rotate(14.753) scale(69.2094 62.0813)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1895_33807"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-38.5751 29.1757) rotate(14.7551) scale(124.556 111.741)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
    </defs>
  </svg>
);

IconControlCenterCostsSet.defaultProps = defaultIconProps;
