import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterPieChartBg: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="83.9941" cy="84" r="80" fill="#F7FAFE" />
    <g filter="url(#filter0_d_1954_30495)">
      <circle cx="83.9941" cy="84" r="60" fill="#F2F6FE" />
    </g>
    <g filter="url(#filter1_d_1954_30495)">
      <circle cx="83.9941" cy="84" r="40" fill="#E6EDFD" />
    </g>
    <g filter="url(#filter2_di_1954_30495)">
      <circle cx="84.8763" cy="80.6807" r="26.8811" transform="rotate(-90.6436 84.8763 80.6807)" fill="url(#paint0_radial_1954_30495)" />
      <circle cx="84.8763" cy="80.6807" r="26.3811" transform="rotate(-90.6436 84.8763 80.6807)" stroke="#99B8F7" />
    </g>
    <g filter="url(#filter3_ddi_1954_30495)">
      <mask id="path-6-inside-1_1954_30495" fill="white">
        <path d="M84.9594 48.1299C80.0922 47.9931 75.2553 48.9377 70.7972 50.8957C66.3391 52.8536 62.3708 55.7761 59.1783 59.4525C55.9859 63.129 53.6487 67.4679 52.3353 72.1565C51.0218 76.8451 50.7648 81.7667 51.5827 86.5666L84.0347 81.0366L84.9594 48.1299Z" />
      </mask>
      <path
        d="M84.9594 48.1299C80.0922 47.9931 75.2553 48.9377 70.7972 50.8957C66.3391 52.8536 62.3708 55.7761 59.1783 59.4525C55.9859 63.129 53.6487 67.4679 52.3353 72.1565C51.0218 76.8451 50.7648 81.7667 51.5827 86.5666L84.0347 81.0366L84.9594 48.1299Z"
        fill="url(#paint1_radial_1954_30495)"
      />
      <path
        d="M84.9594 48.1299C80.0922 47.9931 75.2553 48.9377 70.7972 50.8957C66.3391 52.8536 62.3708 55.7761 59.1783 59.4525C55.9859 63.129 53.6487 67.4679 52.3353 72.1565C51.0218 76.8451 50.7648 81.7667 51.5827 86.5666L84.0347 81.0366L84.9594 48.1299Z"
        stroke="#99B8F7"
        strokeWidth="2"
        mask="url(#path-6-inside-1_1954_30495)"
      />
    </g>
    <ellipse cx="84.5" cy="117" rx="11.5" ry="3" fill="#CCDCFB" />
    <defs>
      <filter id="filter0_d_1954_30495" x="9.99414" y="10" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.39 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30495" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30495" result="shape" />
      </filter>
      <filter id="filter1_d_1954_30495" x="-0.00585938" y="0" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30495" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30495" result="shape" />
      </filter>
      <filter
        id="filter2_di_1954_30495"
        x="57.9961"
        y="53.7995"
        width="53.7617"
        height="56.7623"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0965946 0 0 0 0 0.206323 0 0 0 0 0.754964 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30495" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30495" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="8" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1954_30495" />
      </filter>
      <filter
        id="filter3_ddi_1954_30495"
        x="49.1152"
        y="46.1169"
        width="41.8438"
        height="46.4497"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.499316 0 0 0 0 0.766348 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30495" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.207843 0 0 0 0 0.756863 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_1954_30495" result="effect2_dropShadow_1954_30495" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1954_30495" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="6" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1954_30495" />
      </filter>
      <radialGradient
        id="paint0_radial_1954_30495"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(76.7938 59.7111) rotate(109.229) scale(50.6792)"
      >
        <stop stopColor="#316BFF" />
        <stop offset="1" stopColor="#0240BD" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1954_30495"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56.859 85.388) rotate(31.2102) scale(62.0638)"
      >
        <stop stopColor="#3180FF" />
        <stop offset="1" stopColor="#B5D5FF" />
      </radialGradient>
    </defs>
  </svg>
);

IconControlCenterPieChartBg.defaultProps = defaultIconProps;
