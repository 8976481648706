import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterPeopleSet: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 220 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g filter="url(#filter0_i_1895_34024)">
      <ellipse
        cx="26.8563"
        cy="27.0138"
        rx="26.8563"
        ry="27.0138"
        transform="matrix(0.097356 -0.995235 0.995375 0.0962104 160.393 80.2966)"
        fill="url(#paint0_radial_1895_34024)"
      />
    </g>
    <path
      d="M192.425 30.316C206.796 31.705 217.304 44.4091 215.908 58.6815C214.512 72.954 201.738 83.4077 187.367 82.0186C172.997 80.6296 162.488 67.9255 163.884 53.6531C165.281 39.3806 178.054 28.927 192.425 30.316Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <path
      d="M179.883 64.0125C181.042 62.3817 183.102 61.5122 185.179 61.9688L192.709 63.6245C194.786 64.0812 196.291 65.7346 196.659 67.701M196.033 54.4882C195.424 57.2606 192.682 59.0139 189.91 58.4043C187.137 57.7947 185.384 55.0531 185.993 52.2807C186.603 49.5083 189.345 47.755 192.117 48.3646C194.889 48.9742 196.643 51.7158 196.033 54.4882ZM202.873 59.2812C201.349 66.2122 194.495 70.5954 187.564 69.0715C180.633 67.5475 176.25 60.6935 177.774 53.7625C179.298 46.8315 186.152 42.4483 193.083 43.9722C200.014 45.4962 204.397 52.3502 202.873 59.2812Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="191.053" cy="53.6646" r="4.8159" transform="rotate(20.2492 191.053 53.6646)" fill="white" />
    <path
      d="M196.3 67.2674C195.38 69.7628 186.298 69.1773 183.802 68.2567C181.307 67.3362 180.03 64.567 180.951 62.0717C183.914 60.3647 190.455 62.7777 192.95 63.6983C195.446 64.6188 197.221 64.7721 196.3 67.2674Z"
      fill="white"
    />
    <g filter="url(#filter1_i_1895_34024)">
      <ellipse
        cx="18.5325"
        cy="18.6288"
        rx="18.5325"
        ry="18.6288"
        transform="matrix(0.249737 -0.968279 0.969059 0.246963 126.281 36.2911)"
        fill="url(#paint1_radial_1895_34024)"
      />
    </g>
    <path
      d="M153.37 5.85579C162.873 8.27751 168.594 17.895 166.16 27.3301C163.727 36.7652 154.056 42.4601 144.554 40.0384C135.051 37.6167 129.33 27.9992 131.763 18.5641C134.197 9.12901 143.867 3.43407 153.37 5.85579Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <path
      d="M142.114 29.3857C142.751 28.0953 144.114 27.2423 145.641 27.3291L151.177 27.6436C152.704 27.7304 153.961 28.7323 154.448 30.0865M152.493 20.7762C152.377 22.8145 150.631 24.3729 148.592 24.2571C146.554 24.1413 144.996 22.3951 145.111 20.3568C145.227 18.3186 146.973 16.7602 149.012 16.876C151.05 16.9918 152.608 18.738 152.493 20.7762ZM157.897 23.3974C157.608 28.493 153.242 32.3891 148.147 32.0995C143.051 31.81 139.155 27.4445 139.445 22.3489C139.734 17.2533 144.1 13.3572 149.195 13.6467C154.291 13.9363 158.187 18.3018 157.897 23.3974Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="148.863" cy="20.7609" rx="3.4636" ry="3.4636" transform="rotate(11.1006 148.863 20.7609)" fill="white" />
    <path
      d="M154.144 29.8192C153.776 31.6963 147.26 32.3191 145.383 31.9508C143.506 31.5825 142.283 29.7623 142.651 27.8852C144.56 26.3343 149.48 27.2997 151.358 27.668C153.235 28.0363 154.513 27.9421 154.144 29.8192Z"
      fill="white"
    />
    <g filter="url(#filter2_i_1895_34024)">
      <ellipse
        cx="33.3524"
        cy="33.5306"
        rx="33.3524"
        ry="33.5306"
        transform="matrix(-0.246764 -0.969107 0.969747 -0.243987 16.6367 95.8239)"
        fill="url(#paint2_radial_1895_34024)"
      />
    </g>
    <path
      d="M32.91 23.8527C50.4006 19.4521 68.1602 29.9797 72.5844 47.3548C77.0086 64.7298 66.426 82.3883 48.9355 86.7889C31.4449 91.1895 13.6853 80.6619 9.2611 63.2868C4.83689 45.9118 15.4195 28.2533 32.91 23.8527Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <path
      d="M29.9658 69.6512C30.6832 67.0014 32.922 64.9036 35.8129 64.5051L46.2929 63.0605C49.1837 62.662 51.9066 64.0758 53.3142 66.4327M46.2337 49.7197C46.7656 53.5784 44.0688 57.1376 40.2102 57.6695C36.3515 58.2014 32.7923 55.5045 32.2604 51.6459C31.7285 47.7873 34.4254 44.2281 38.284 43.6962C42.1426 43.1643 45.7018 45.8611 46.2337 49.7197ZM57.3157 52.6418C58.6454 62.2884 51.9033 71.1864 42.2567 72.5162C32.6102 73.8459 23.7121 67.1038 22.3823 57.4572C21.0526 47.8106 27.7947 38.9126 37.4413 37.5828C47.0879 36.2531 55.9859 42.9952 57.3157 52.6418Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="39.4287" cy="51.025" r="6.60835" fill="white" />
    <path
      d="M52.6461 66.0442C52.6461 69.6939 40.676 73.2533 37.0263 73.2533C33.3766 73.2533 30.418 70.2946 30.418 66.645C33.4218 63.0402 42.9888 63.0402 46.6385 63.0402C50.2882 63.0402 52.6461 62.3945 52.6461 66.0442Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_1895_34024"
        x="162.879"
        y="29.311"
        width="54.0352"
        height="53.7126"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.0498" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_34024" />
      </filter>
      <filter
        id="filter1_i_1895_34024"
        x="130.32"
        y="4.41766"
        width="37.2832"
        height="37.0588"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.0498" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_34024" />
      </filter>
      <filter
        id="filter2_i_1895_34024"
        x="7.37305"
        y="21.9715"
        width="67.0996"
        height="66.6986"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.0498" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_34024" />
      </filter>
      <radialGradient
        id="paint0_radial_1895_34024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-31.0622 23.5053) rotate(14.7622) scale(100.3 90.0211)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1895_34024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-21.4348 16.2093) rotate(14.7528) scale(69.2103 62.0813)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1895_34024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-38.5757 29.1757) rotate(14.7548) scale(124.557 111.742)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
    </defs>
  </svg>
);

IconControlCenterPeopleSet.defaultProps = defaultIconProps;
