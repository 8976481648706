import { cx } from '@linaria/core';
import { NotFound } from 'components/ErrorUI/NotFound/NotFound';
import { ConnectionError } from 'components/ErrorUI/ConnectionError/ConnectionError';
import { IconSearch } from 'components/Icons';
import { Spinner } from 'components/Spinner/Spinner';
import { textTitle } from 'styles/typography';
import { either } from 'fp-ts';
import { Either } from 'fp-ts/lib/Either';
import React from 'react';
import { EmptyObject } from 'types/empty';
import { isObject } from 'utils/general';
import { errorStateContainer } from '../uiStates.styles';

export enum UI_STATES {
  loading = 'LOADING',
  error = 'ERROR',
  connectionError = 'CONNECTION_ERROR',
  empty = 'EMPTY',
  idle = 'IDLE',

  // if this is somehow reaching your component, your logic is invalid and needs to be debugged
  invalid = 'INVALID_OR_UNKNOWN',
}
export const UI_STATES_ARRAY = Object.values(UI_STATES);
export type ComponentMap<Props> = Partial<{ [key in UI_STATES]: (props?: Props) => JSX.Element | null }>;
export type DefaultLeftProps = { title?: string | undefined; reason?: string | undefined; showCTA?: boolean };

const defaultComponentMap: Record<UI_STATES, React.FC<DefaultLeftProps>> = {
  // NOTE: please maintain the testids when replacing these components with better UIs
  [UI_STATES.loading]: Spinner,
  [UI_STATES.error]: (props) => {
    return (
      <div data-testid="default-error-ui">
        <NotFound title={props.title} description={props.reason} showCTA={props.showCTA} />
      </div>
    );
  },
  [UI_STATES.connectionError]: () => {
    return (
      <div data-testid="default-error-ui">
        <ConnectionError />
      </div>
    );
  },
  [UI_STATES.empty]: () => (
    <div data-testid="default-empty-ui" className={errorStateContainer}>
      <IconSearch size={30} color="#195AFF" />
      <h3 className={cx(textTitle, 'title')}>No results found</h3>
    </div>
  ),
  [UI_STATES.invalid]: () => <div data-testid="default-invalid-ui" />,
  [UI_STATES.idle]: () => <div data-testid="default-idle-ui" />,
};

export type ReactLeft<
  // eslint-disable-next-line @typescript-eslint/ban-types
  ComponentProps = EmptyObject,
> = { type: UI_STATES; props?: DefaultLeftProps & ComponentProps };

const hasValidReactLeftType = (error: Record<string, unknown>): boolean => {
  return 'type' in error && UI_STATES_ARRAY.includes(error.type as UI_STATES);
};

const hasValidReactLeftProps = (error: Record<string, unknown>): boolean => {
  return 'props' in error ? isObject(error.props) : true;
};

export function isReactLeft(error: unknown): error is ReactLeft {
  return isObject(error) && hasValidReactLeftType(error as Record<string, unknown>) && hasValidReactLeftProps(error as Record<string, unknown>);
}

export function reactLeftToJSX<ComponentProps = { reason?: string | undefined }>(
  componentMap?: ComponentMap<ComponentProps>,
  config?: { showErrorCTA: boolean },
): (left: ReactLeft<ComponentProps>) => JSX.Element {
  const map = {
    ...defaultComponentMap,
    ...(componentMap || {}),
  };
  return (left) => {
    const type = left.type;
    const props = (left.props || {}) as unknown as ComponentProps;

    const Component = map[type] || (defaultComponentMap.EMPTY as unknown as React.FC<ComponentProps>);
    return <Component {...props} showCTA={config?.showErrorCTA} />;
  };
}

export const MakeReactLeft = {
  loading<P, R>(props?: P): Either<ReactLeft<P>, R> {
    return either.left({
      type: UI_STATES.loading,
      ...(props ? { props } : {}),
    });
  },
  error<P, R>(props?: P): Either<ReactLeft<P>, R> {
    return either.left({
      type: UI_STATES.error,
      ...(props ? { props } : {}),
    });
  },
  empty<P, R>(props?: P): Either<ReactLeft<P>, R> {
    return either.left({
      type: UI_STATES.empty,
      ...(props ? { props } : {}),
    });
  },
  invalid<P, R>(props?: P): Either<ReactLeft<P>, R> {
    return either.left({
      type: UI_STATES.invalid,
      ...(props ? { props } : {}),
    });
  },
};

export function isLeftOfLoading(eitherData: either.Either<ReactLeft, unknown>): boolean {
  return either.isLeft(eitherData) && eitherData.left.type === UI_STATES.loading;
}

export const mapStatesToComponent = <P,>(states: UI_STATES[], component: (props?: P) => JSX.Element | null): ComponentMap<P> => {
  const map: Partial<Record<UI_STATES, (props?: P) => JSX.Element | null>> = {};

  states.forEach((state) => {
    map[state] = component;
  });

  return map;
};
