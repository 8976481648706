import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterBarChartBg: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="83.9941" cy="84" r="80" fill="#F7FAFE" />
    <g filter="url(#filter0_d_1954_30438)">
      <circle cx="83.9941" cy="84" r="60" fill="#F2F6FE" />
    </g>
    <g filter="url(#filter1_d_1954_30438)">
      <circle cx="83.9941" cy="84" r="40" fill="#E6EDFD" />
    </g>
    <ellipse cx="64.5" cy="124" rx="7.5" ry="2" fill="#CCDCFB" />
    <ellipse cx="85.5" cy="121" rx="7.5" ry="2" fill="#CCDCFB" />
    <ellipse cx="105.5" cy="117" rx="7.5" ry="2" fill="#CCDCFB" />
    <g filter="url(#filter2_ddi_1954_30438)">
      <path
        d="M100.46 102.411H111.54C112.711 102.411 113.661 101.461 113.661 100.29L113.661 52.46C113.661 51.2887 112.711 50.3392 111.54 50.3392H100.46C99.2887 50.3392 98.3392 51.2887 98.3392 52.46L98.3392 100.29C98.3392 101.461 99.2887 102.411 100.46 102.411Z"
        fill="url(#paint0_radial_1954_30438)"
        stroke="#6695F3"
        strokeWidth="1.32168"
      />
    </g>
    <g filter="url(#filter3_ddi_1954_30438)">
      <path
        d="M79.46 107.953H90.54C91.7113 107.953 92.6608 107.003 92.6608 105.832V66.4595C92.6608 65.2882 91.7113 64.3387 90.54 64.3387H79.46C78.2887 64.3387 77.3392 65.2882 77.3392 66.4595V105.832C77.3392 107.003 78.2887 107.953 79.46 107.953Z"
        fill="url(#paint1_radial_1954_30438)"
        stroke="#6695F3"
        strokeWidth="1.32168"
      />
    </g>
    <g filter="url(#filter4_ddi_1954_30438)">
      <path
        d="M57.46 112.411H68.54C69.7113 112.411 70.6608 111.462 70.6608 110.29V85.4187C70.6608 84.2474 69.7113 83.2979 68.54 83.2979H57.46C56.2887 83.2979 55.3392 84.2474 55.3392 85.4187L55.3392 110.29C55.3392 111.462 56.2887 112.411 57.46 112.411Z"
        fill="url(#paint2_radial_1954_30438)"
        stroke="#6695F3"
        strokeWidth="1.32168"
      />
    </g>
    <defs>
      <filter id="filter0_d_1954_30438" x="9.99414" y="10" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.39 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30438" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30438" result="shape" />
      </filter>
      <filter id="filter1_d_1954_30438" x="-0.00585938" y="0" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0980392 0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30438" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1954_30438" result="shape" />
      </filter>
      <filter
        id="filter2_ddi_1954_30438"
        x="78.585"
        y="33.3131"
        width="54.83"
        height="91.5789"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.72753" />
        <feGaussianBlur stdDeviation="9.54637" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30438" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.64336" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_1954_30438" result="effect2_dropShadow_1954_30438" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1954_30438" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.92539" dy="5.53784" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1954_30438" />
      </filter>
      <filter
        id="filter3_ddi_1954_30438"
        x="57.585"
        y="47.3126"
        width="54.83"
        height="83.1211"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.72753" />
        <feGaussianBlur stdDeviation="9.54637" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30438" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.64336" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_1954_30438" result="effect2_dropShadow_1954_30438" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1954_30438" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.92539" dy="5.53784" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1954_30438" />
      </filter>
      <filter
        id="filter4_ddi_1954_30438"
        x="35.585"
        y="66.2718"
        width="54.83"
        height="68.6205"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.72753" />
        <feGaussianBlur stdDeviation="9.54637" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.415686 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1954_30438" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.64336" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.243137 0 0 0 0 0.737255 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_1954_30438" result="effect2_dropShadow_1954_30438" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1954_30438" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.92539" dy="5.53784" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1954_30438" />
      </filter>
      <radialGradient
        id="paint0_radial_1954_30438"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(103.39 59.6666) rotate(77.5055) scale(45.1911 25.2963)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1954_30438"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(82.3896 72.2218) rotate(75.1091) scale(38.0455 25.0398)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1954_30438"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(60.3896 88.7047) rotate(67.9693) scale(26.0645 24.0181)"
      >
        <stop offset="0.221581" stopColor="#306AFF" />
        <stop offset="0.729167" stopColor="#003EBC" />
      </radialGradient>
    </defs>
  </svg>
);

IconControlCenterBarChartBg.defaultProps = defaultIconProps;
