import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterInputsSet: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 220 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g filter="url(#filter0_i_1895_33914)">
      <ellipse
        cx="26.8563"
        cy="27.0138"
        rx="26.8563"
        ry="27.0138"
        transform="matrix(0.097356 -0.995235 0.995375 0.0962104 160.393 80.2966)"
        fill="url(#paint0_radial_1895_33914)"
      />
    </g>
    <path
      d="M192.425 30.316C206.796 31.705 217.304 44.4091 215.908 58.6815C214.512 72.954 201.738 83.4077 187.367 82.0186C172.997 80.6296 162.488 67.9255 163.884 53.6531C165.281 39.3806 178.054 28.927 192.425 30.316Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <path
      d="M182.318 41.8225C182.716 40.4178 184.178 39.6019 185.583 40.0002L196.494 43.094C197.237 43.3045 197.85 43.8289 198.174 44.5297L200.217 48.9584L201.926 52.8005C202.177 53.3656 202.222 54.0009 202.054 54.5959L197.808 69.5718C197.409 70.9765 195.948 71.7924 194.543 71.3941L178.01 66.7067C176.606 66.3084 175.79 64.8468 176.188 63.4421L182.318 41.8225Z"
      fill="white"
    />
    <rect x="183.965" y="45.7258" width="15.8624" height="1.9828" rx="0.991399" transform="rotate(15.8295 183.965 45.7258)" fill="#87ACF7" />
    <rect x="181.439" y="54.6277" width="15.8624" height="1.9828" rx="0.991399" transform="rotate(15.8295 181.439 54.6277)" fill="#87ACF7" />
    <rect x="182.699" y="50.1752" width="15.8624" height="1.9828" rx="0.991399" transform="rotate(15.8295 182.699 50.1752)" fill="#87ACF7" />
    <rect x="180.17" y="59.0734" width="9.91399" height="1.9828" rx="0.991399" transform="rotate(15.8295 180.17 59.0734)" fill="#87ACF7" />
    <path
      d="M194.674 50.7509L196.485 44.4447C196.752 43.5161 198.03 43.4216 198.431 44.3009L202.378 52.9731C202.798 53.8962 201.945 54.888 200.969 54.6101L196.037 53.2051C194.982 52.9046 194.372 51.805 194.674 50.7509Z"
      fill="#C6D7FD"
    />
    <g filter="url(#filter1_i_1895_33914)">
      <ellipse
        cx="18.5325"
        cy="18.6288"
        rx="18.5325"
        ry="18.6288"
        transform="matrix(0.249737 -0.968279 0.969059 0.246963 126.281 36.2911)"
        fill="url(#paint1_radial_1895_33914)"
      />
    </g>
    <path
      d="M153.37 5.85579C162.873 8.27751 168.594 17.895 166.16 27.3301C163.727 36.7652 154.056 42.4601 144.554 40.0384C135.051 37.6167 129.33 27.9992 131.763 18.5641C134.197 9.12901 143.867 3.43407 153.37 5.85579Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <g filter="url(#filter2_d_1895_33914)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.243 14.6117C142.54 14.3844 140.976 15.5805 140.749 17.2831L139.411 27.3025C139.184 29.0052 140.38 30.5696 142.083 30.7969L154.414 32.4428C156.117 32.6701 157.681 31.4741 157.909 29.7714L159.246 19.752C159.473 18.0494 158.277 16.4849 156.575 16.2576L144.243 14.6117ZM157.908 18.0037L156.367 17.7979L156.161 19.3394L157.702 19.5451L157.908 18.0037ZM142.493 15.9462L144.035 16.152L143.829 17.6934L142.288 17.4877L142.493 15.9462ZM157.6 20.3161L156.059 20.1104L155.853 21.6518L157.395 21.8576L157.6 20.3161ZM142.186 18.2587L143.727 18.4644L143.521 20.0059L141.98 19.8001L142.186 18.2587ZM157.297 22.6311L155.755 22.4253L155.55 23.9668L157.091 24.1725L157.297 22.6311ZM141.889 20.5769L143.431 20.7827L143.225 22.3241L141.683 22.1184L141.889 20.5769ZM156.982 24.9402L155.441 24.7345L155.235 26.2759L156.776 26.4817L156.982 24.9402ZM141.574 22.8861L143.116 23.0918L142.91 24.6333L141.369 24.4276L141.574 22.8861ZM156.674 27.2527L155.133 27.047L154.927 28.5884L156.469 28.7941L156.674 27.2527ZM141.26 25.1953L142.801 25.401L142.596 26.9425L141.054 26.7367L141.26 25.1953ZM156.371 29.5671L154.83 29.3613L154.624 30.9028L156.166 31.1085L156.371 29.5671ZM140.951 27.5072L142.492 27.7129L142.287 29.2544L140.745 29.0486L140.951 27.5072ZM148.374 20.7672C147.898 20.4172 147.221 20.7051 147.143 21.2908L146.879 23.2634L146.616 25.236C146.538 25.8217 147.116 26.2771 147.667 26.0642L151.53 24.5722C152.103 24.351 152.206 23.5841 151.711 23.2204L148.374 20.7672Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter3_i_1895_33914)">
      <ellipse
        cx="33.3524"
        cy="33.5306"
        rx="33.3524"
        ry="33.5306"
        transform="matrix(-0.246764 -0.969107 0.969747 -0.243987 16.6367 95.8239)"
        fill="url(#paint2_radial_1895_33914)"
      />
    </g>
    <path
      d="M32.91 23.8527C50.4006 19.4521 68.1602 29.9797 72.5844 47.3548C77.0086 64.7298 66.426 82.3883 48.9355 86.7889C31.4449 91.1895 13.6853 80.6619 9.2611 63.2868C4.83689 45.9118 15.4195 28.2533 32.91 23.8527Z"
      stroke="#99B8F7"
      strokeWidth="1.76245"
    />
    <g filter="url(#filter4_d_1895_33914)">
      <path
        d="M41.8611 54.4745C43.6628 49.6789 49.9946 48.6585 53.2115 52.6454L61.9259 63.4458C63.8663 65.8506 62.4848 69.4544 59.4342 69.946L45.8049 72.1422C40.7862 72.951 36.7661 68.0362 38.5539 63.2774L41.8611 54.4745Z"
        fill="url(#paint3_linear_1895_33914)"
      />
    </g>
    <g filter="url(#filter5_d_1895_33914)">
      <path
        d="M29.1899 52.3395C30.8745 47.2517 37.5907 46.1694 40.7883 50.4705L51.7567 65.2242C53.5583 67.6475 52.1485 71.118 49.1673 71.5984L27.8717 75.0299C24.8906 75.5103 22.462 72.6584 23.4111 69.7919L29.1899 52.3395Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter6_d_1895_33914)">
      <ellipse cx="51.1455" cy="38.0391" rx="3.3488" ry="3.3488" transform="rotate(-9.15401 51.1455 38.0391)" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_i_1895_33914"
        x="162.879"
        y="29.311"
        width="54.0352"
        height="53.7126"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.0498" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_33914" />
      </filter>
      <filter
        id="filter1_i_1895_33914"
        x="130.32"
        y="4.41766"
        width="37.2832"
        height="37.0588"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.0498" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_33914" />
      </filter>
      <filter
        id="filter2_d_1895_33914"
        x="135.495"
        y="11.4737"
        width="27.6662"
        height="25.6621"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.77756" />
        <feGaussianBlur stdDeviation="1.9439" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.94651 0 0 0 0 0.968627 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1895_33914" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1895_33914" result="shape" />
      </filter>
      <filter
        id="filter3_i_1895_33914"
        x="7.37305"
        y="21.9715"
        width="67.0996"
        height="66.6986"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="7.0498" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1895_33914" />
      </filter>
      <filter
        id="filter4_d_1895_33914"
        x="32.763"
        y="44.8224"
        width="35.4173"
        height="32.7658"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.67904" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1895_33914" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1895_33914" result="shape" />
      </filter>
      <filter
        id="filter5_d_1895_33914"
        x="17.845"
        y="42.4398"
        width="40.0677"
        height="38.0015"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.67904" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1895_33914" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1895_33914" result="shape" />
      </filter>
      <filter
        id="filter6_d_1895_33914"
        x="42.4388"
        y="29.3317"
        width="17.4134"
        height="17.4148"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2.67904" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.721569 0 0 0 0 0.968627 0 0 0 0.9 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1895_33914" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1895_33914" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_1895_33914"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-31.0622 23.5053) rotate(14.7622) scale(100.3 90.0211)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1895_33914"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-21.4348 16.2093) rotate(14.7528) scale(69.2103 62.0813)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1895_33914"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-38.5757 29.1757) rotate(14.7548) scale(124.557 111.742)"
      >
        <stop offset="0.42" stopColor="#6695F3" />
        <stop offset="1" stopColor="#D9E4FF" />
      </radialGradient>
      <linearGradient id="paint3_linear_1895_33914" x1="48.3133" y1="55.9215" x2="24.7243" y2="76.8167" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F6FE" />
        <stop offset="1" stopColor="#0046D4" />
      </linearGradient>
    </defs>
  </svg>
);

IconControlCenterInputsSet.defaultProps = defaultIconProps;
