import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import getConfig from 'next/config';
import { PublicRuntimeConfig } from 'server/configuration';
import { appStorage } from 'utils/appStorage';
import { createLDContext } from './context';

const config: PublicRuntimeConfig = getConfig().publicRuntimeConfig;

const sdkKey = config.LD_SDK_KEY;
const clientSideID = config.LD_CLIENT_SIDE_ID;

export interface FeatureFlagsType {
  inputManagerSearchResultCountIndicator: boolean;
  'input-viewer-sidebar-ai-assist-panel': boolean;
  'input-viewer-video-track-timeline': boolean;
  'model-creation-huggingface-import': boolean;
  'model-creation-triton-upload': boolean;
  batchPredictModal: boolean;
  'leaderboard-panel': boolean;
  'primary-input-type-app-creation': boolean;
  'upgrade-app-to-universal-workflow-modal-button': boolean;
  'filter-by-input-type': boolean;
  'show-inputs-counts': boolean;
  'annotations-mode': boolean;
  'default-base-workflow-option-for-text': string;
  'default-base-workflow-option-for-image': string;
  changelog: string;
  'documentation-link-lookup': Record<string, string>;
  'inputs-manager-grid-video-controls': boolean;
  'filter-by-status': boolean;
  'filter-by-labeler': boolean;
  'filters-tooltip-text': Record<string, string>;
  modelVersionTableV2: boolean;
  'view-annotation-metrics-button': boolean;
  'show-image-mask-annotations': boolean;
  thirdPartyApiKeyAvailableLDFF: boolean;
  autoAnnotationLabelingTaskFF: boolean;
  enableTemplateFeatureLdff: boolean;
  textGenImageUploadLDFF: boolean;
  'hide-similarity-search-for-text': boolean;
  'enable-resource-pricing': boolean;
  'show-go-to-old-task-review-screen-ldff': boolean;
  resourceVersionSelectorFF: boolean;
  resourceVersionSelectorInModelFF: boolean;
  'enable-dataset-listing': boolean;
  modulePublishing: boolean;
  nodePoolDeploymentsLDFF: boolean;
  controlCenter: boolean;
  controlCenterCosts: boolean;
  editNodePoolDeploymentsLDFF: boolean;
  workflowVersionEvaluation: boolean;
}

export function getLaunchDarklySDKKey() {
  return sdkKey;
}

export function getLaunchDarklyClientSideID() {
  return clientSideID;
}

export function getLDContext(): LDSingleKindContext | undefined {
  const authData = appStorage.get<CF.LocalUserWithOrg | undefined>('authData');
  return createLDContext(authData);
}
